export default {
    title: 'IKEA Family Share of Sales',
    location: '/business/ikea-family-share-of-sales',
    tabs: [
        {
            name: 'By Country',
            link: `/business/ikea-family-share-of-sales`
        },
        {
            name: 'Trend',
            link: `/business/ikea-family-share-of-sales/:market`
        },
        {
            name: 'Top 10',
            link: '/business/ikea-family-share-of-sales/top-10'
        }
    ]
}